<template>
	<div>
		<!-- top -->
		<div class="top">
			<div class="left">
				<div class="select">
					<el-date-picker
						v-model="timeQuantum"
						type="daterange"
						range-separator="-"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						size="mini">
					</el-date-picker>
				  <el-radio-group v-model="month" size="mini">
						<el-radio-button label="今日"></el-radio-button>
						<el-radio-button label="昨天"></el-radio-button>
						<el-radio-button label="本月"></el-radio-button>
						<el-radio-button label="上月"></el-radio-button>
					</el-radio-group>
				</div>
			</div>
			<div class="right">
				<el-button size="mini" type="primary">保存为常用</el-button>
				<el-button size="mini" type="primary">更多</el-button>
			</div>
		</div><!-- end top -->  
		<el-row>
		  <el-col :span="4">
				<div>
					<p class="color">劳动业绩</p>
					<p class="size">
						<span>25,477</span>
						<span>
							<i class="el-icon-caret-bottom"></i>
							<i>-31.95%</i>
						</span>
					</p>
				</div>
			</el-col>
		  <el-col :span="4">
				<div>
					<div>
						<p class="color">客数</p>
						<p class="size">
							<span>457</span>
							<span>
								<i class="el-icon-caret-bottom"></i>
								<i>-31.95%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
		  <el-col :span="4">
				<div>
					<div>
						<p class="color">指定客</p>
						<p class="size">
							<span>188</span>
							<span>
								<i class="el-icon-caret-bottom"></i>
								<i>-31.95%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
		  <el-col :span="4">
				<div>
					<div>
						<p class="color">客单价</p>
						<p class="size">
							<span>622</span>
							<span>
								<i class="el-icon-caret-bottom"></i>
								<i>-31.95%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div>
					<div>
						<p class="color">指定率</p>
						<p class="size">
							<span>41.14%</span>
							<span>
								<i class="el-icon-caret-bottom"></i>
								<i>-31.95%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div>
					<div>
						<p class="color">项目数</p>
						<p class="size">
							<span>25,477</span>
							<span>
								<i class="el-icon-caret-top"></i>
								<i>+31.95%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
		</el-row>
		<div class="top">
			<div class="center">
				<span>查看：</span>
				<el-radio-group v-model="category" size="mini">
					<el-radio-button label="找项目"></el-radio-button>
					<el-radio-button label="找分类"></el-radio-button>
					<el-radio-button label="找部门"></el-radio-button>
				</el-radio-group>
				<span class="prompt">{{category}}筛选：</span>
				<el-select v-model="selectValue" placeholder="请选择" size="mini">
					<el-option
						v-for="(item,index) in screenData"
						:key="index"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
				<el-popover
					placement="bottom"
					width="300"
					trigger="click">
					<div class="detaill">
						<div class="top">
							<span>显示</span>
							<span>恢复默认</span>
						</div>
						<div class="center">
							<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
							  <el-checkbox 
								v-for="(city,index) in cities" 
								:key="index"
								:label="city">
								  {{city}}
								</el-checkbox>
							</el-checkbox-group>
						</div>
						<div class="bottom">
							<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
							<el-button type="primary" size="mini">确定</el-button>
						</div>
					</div>
					<div class="look" slot="reference">
						<el-button icon="el-icon-s-grid" size="mini"></el-button>
					</div>
				</el-popover>
			</div>
		</div>
		<el-table
			:data="tableData"
			border
			show-summary
			:summary-method="getSummaries"
			max-height="540"
			style="width: 100%; margin-top: 20px"
			size="small">
			<el-table-column
				prop="id"
				label="部门名称"
				width="300"
				fixed>
			</el-table-column>
			<el-table-column
				prop="name"
				label="服务记客数"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount1"
				label="劳动业绩"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount2"
				label="销售数量"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="单价"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="数量占比"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="指定数"
				width="100">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="指定业绩"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="指定业绩比"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="非指定数"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="非指定业绩"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="非指定业绩比"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="指定率"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="现金业绩"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="现金占比"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="卡金业绩"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="卡金占比"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="疗程抵扣业绩"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="抵扣次数"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="疗程占比"
				width="150">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="转化率"
				width="150">
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				select: '劳动', 
				month: '今日',
				timeQuantum: '' ,//选中的时间
				category: '找项目',
				screenData: [
					{ value: '选项1', label: '高级洗吹' },
					{ value: '选项1', label: '高级洗吹' }
				],
				selectValue: '',
				tableData: [
					{ id: '还款', name: '4', amount1: '234', amount2: '3.2', amount3: 10 },
					{ id: '美发部', name: '4', amount1: '234', amount2: '3.2', amount3: 10 },
					{ id: '12987122', name: '4', amount1: '234', amount2: '3.2', amount3: 10 },
					{ id: '12987122', name: '3', amount1: '234', amount2: '3.2', amount3: 10 },
					{ id: '12987122', name: '3', amount1: '234', amount2: '3.2', amount3: 10 },
					{ id: '12987122', name: '1', amount1: '234', amount2: '3.2', amount3: 10 },
					{ id: '12987122', name: '王小虎', amount1: '234', amount2: '3.2', amount3: 10 },
					{ id: '12987122', name: '王小虎', amount1: '234', amount2: '3.2', amount3: 10 }
				],
				cities: ['上海', '北京', '广州', '深圳'],
				checkedCities: ['上海', '北京'],
				isIndeterminate: true,
				checkAll: false,
			}
		},
		methods:{
			getSummaries(param) {
				const { columns, data } = param;
				const sums = [];
				console.log(columns)
				console.log(data)
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = '总价';
						return;
					}
					const values = data.map(item => Number(item[column.property]));
					if (!values.every(value => isNaN(value))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0);
						sums[index] += ' 元';
					} else {
						sums[index] = '--';
					}
				});

				return sums;
			},
			handleCheckedCitiesChange(value){
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			},
			handleCheckAllChange(val) {
				this.checkedCities = val ? this.cities : [];
				this.isIndeterminate = false;
			},
		}
	}
</script>

<style lang="less" scoped>
.top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
	.left{
		display: flex;
		align-items: center;
		.select{
			display: flex;
			align-items: center;
			.el-input__inner{
				width: 220px !important;
			}
			.el-radio-group{
				margin: 0 10px;
			}
		}
		span{
			font-size: 16px;
		}
		i{
			font-style: normal;
		}
	}
	.right{
		display: flex;
		align-items: center;
		span{
			margin-left: 30px;
			color: #475669;
		}
	}
	.el-button{
		margin-left: 10px;
	}
	.prompt{
		margin-left: 20px;
	}
	.center{
		display: flex;
		align-items: center;
	}
}
.el-row{
	margin: 30px 0;
	padding: 20px 0;
	border: 1px solid #d3dce6;
	border-radius: 5px;
	.el-col{
		border-right: 1px solid #d3dce6;
		padding: 0 20px;
		p{
			margin: 0;
			line-height: 2;
		}
		.color{
			color: #8492a6;
		}
		.size>span:first-child{
			font-size: 18px;
		}
		.size>span:last-child{
			color: #30c27c;
			margin-left: 20px;
		}
	}
	.el-col:last-child{
		border-right: none;
	}
}

</style>
